import "./App.css";

// import { Routes, Route } from "react-router-dom";

// import Header from "./routes/header/header.component";
// import About from "./routes/about/about.component";
// import WaysToHelp from "./routes/ways-to-help/ways-to-help.component";
// import OurSuccess from "./routes/our-success/our-success.component";
// import Donate from "./routes/donate/donate.component";
// import Footer from "./components/footer/footer.component";
import BackgroundWithOverlay from './BackgroundWithOverlay'; // Adjust path as needed

const backgroundImage = './Coming_Soon.JPG'; // Adjust path to your background image
const overlayImage = './Coming_Soon.JPG'; // Adjust path to your overlay image

function App() {
  return (
    <div>
      <BackgroundWithOverlay backgroundImage={backgroundImage} overlayImage={overlayImage} />
      {/* Your other content here */}
    </div>
  );
}

export default App;
