import React from "react";

const BackgroundWithOverlay = ({ backgroundImage, overlayImage }) => {
  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100vh", // Adjust as needed
  };

  const backgroundStyle =  () => {
    const { innerWidth: width } = window;
    const backgroundStyle = {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      width: "100%",
      height: "100%",
    }
    if (width < 500) {
      backgroundStyle.display = 'none';
      backgroundStyle.backgroundSize = "cover";
      backgroundStyle.zIndex = '1000';
    }
    return (backgroundStyle)
  };

  const overlayStyle = () => {
    const { innerWidth: width } = window;
    const overlayStyle = {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100vh",
      backgroundImage: `url(${overlayImage})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      opacity: 1, // Adjust opacity as needed
    };
    if (width < 500) {
      overlayStyle.backgroundColor = 'black';
    }
    return (overlayStyle)
  };

  return (
    <div style={containerStyle}>
      <div style={backgroundStyle()}></div>
      <div style={overlayStyle()}></div>
    </div>
  );
};

export default BackgroundWithOverlay;
